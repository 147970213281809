import Layout from "../../../components/layout";
import { Link } from "gatsby";
import MainContent from "../../../components/maincontent";
import PageBanner from "../../../components/banner/banner";
import React from "react";
import Schedule from "../../../components/schedule/schedule";
import Seo from "../../../components/seo";

const IndexPage = () => (
  <Layout>
    <Seo
      title="Camp 2019 - Schedule"
      description="Schedule for Charity Grace Bible Camp 2019"
      lang="en"
      meta={[
        {
          content: "God, Jesus, Gospel, Heaven, Salvation, Saved, Grace, Free, How, Mid-Acts, Romans, Charity Grace Bible Church, Illinois",
          name: "keywords"
        }
      ]}
    />

    <MainContent
      hasArticle={false}
      hasBanner={false}
    >
      <div className="grid--4 grid-padding align--center">
        <Link className="button button--grey" to="/camp/">
          <i className="fa fa-caret-left" aria-hidden="true" /> Back
        </Link>
      </div>

      <div className="grid--4 grid-padding align--center">
        <h2>Camp Schedule 2019</h2>
        <span className="caption">October 10th - October 13th</span>
      </div>

      <div className="grid--4 grid-padding align--center">
        <a
          href="/assets/pdf/CharityGraceBibleCamp2019.pdf"
          className="button button--green"
          download=""
        >
          <i className="fa fa-file-pdf-o" aria-hidden="true" /> Download PDF
        </a>
      </div>
    </MainContent>
    <Schedule />
  </Layout>
);

export default IndexPage;
