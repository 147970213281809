import React from "react";

class RegisterForm extends React.Component {

  render() {
    return (
      <table className="table">
        <thead>
          <tr id="Thursday" />
          <tr className="table-header float-left">
            <th className="table-column table-column--2b">
              <p>Day</p>
            </th>
            <th className="table-column table-column--2b">
              <p className="column-label--time">Time</p>
            </th>
            <th className="table-column table-column--2b">
              <p className="column-label--event">Event</p>
            </th>
            <th className="table-column table-column--2b">
              <p className="column-label--notes">Notes</p>
            </th>
            <th className="table-column table-column--2b">
              <p className="column-label--where">Location</p>
            </th>
          </tr>
        </thead>
        <tbody>

          <tr className="table-row table-color--purple">
            <td className="table-column table-column--2b">
              <h2 className="row-label">Thursday</h2>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--time">2:00PM-4:00PM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">Arrival and Check-in</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes">Paperwork, orientation, map and rules.</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Chapel</p>
            </td>
          </tr>

          <tr className="table-row table-color--purple">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">4:00PM-6:00PM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">Fellowship</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes">Sandwiches and refreshments</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Dining Hall</p>
            </td>
          </tr>

          <tr className="table-row table-color--purple">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">6:30PM-7:30PM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">[Message] &quot;What God Says About His Word&quot;</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes">Speaker: </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Chapel</p>
            </td>
          </tr>

          <tr className="table-row table-color--purple">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">8:00PM-9:00PM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">[Message]</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes">Speaker:</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Chapel</p>
            </td>
          </tr>

          <tr className="table-row table-color--purple">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">9:00PM-10:30PM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">Fellowship and Music</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes"> - </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Dining Hall</p>
            </td>
          </tr>

          <tr id="Friday" />
          <tr className="table-row table-color--purple">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">11:00PM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">Bedtime</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes">Rest up for a good day tomorrow!</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Cabins</p>
            </td>
          </tr>

          <tr className="table-row table-color--blue">
            <td className="table-column table-column--2b">
              <h2 className="row-label">Friday</h2>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--time">6:30AM-8:00AM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">Fellowship Coffee and Muffins</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes"> - </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Dining Hall</p>
            </td>
          </tr>

          <tr className="table-row table-color--blue">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">7:30AM-8:00AM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">[Optional] Calisthenics</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes"> Need Volunteers </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Outdoors</p>
            </td>
          </tr>

          <tr className="table-row table-color--blue">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">8:00AM-9:00AM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">Breakfast</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes"> - </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Dining Hall</p>
            </td>
          </tr>

          <tr className="table-row table-color--blue">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">9:30AM-10:45AM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">[Message] </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes">Speaker: </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Chapel</p>
            </td>
          </tr>

          <tr className="table-row table-color--blue">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">10:45AM-11:00AM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">Break</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes">Stretch Legs</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Chapel</p>
            </td>
          </tr>

          <tr className="table-row table-color--blue">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">11:00AM-12:00PM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">[Message]</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes">Speaker: </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Chapel</p>
            </td>
          </tr>

          <tr className="table-row table-color--blue">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">12:00PM-1:30PM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">Lunch</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes"> - </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Dining Hall</p>
            </td>
          </tr>

          <tr className="table-row table-color--blue">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">1:30PM-3:30PM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">Free Time</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes"> - </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where"> - </p>
            </td>
          </tr>

          <tr className="table-row table-color--blue">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">3:30PM-4:30PM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">[Message] </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes"> Speaker: </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where"> TBD </p>
            </td>
          </tr>

          <tr className="table-row table-color--blue">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">5:30PM-7:00PM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">Supper</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes"> - </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Dining Hall</p>
            </td>
          </tr>

          <tr className="table-row table-color--blue">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">7:00PM-8:30PM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">[Message] </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes">Speaker:</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Chapel</p>
            </td>
          </tr>

          <tr className="table-row table-color--blue">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">8:30PM-9:30PM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">Music and Fellowship</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes"> - </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Chapel</p>
            </td>
          </tr>

          <tr className="table-row table-color--blue">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">9:30PM-10:30PM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">Youth Fellowship</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes"> - </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Dining Hall</p>
            </td>
          </tr>

          <tr id="Saturday" />
          <tr className="table-row table-color--blue">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">11:00PM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">Bedtime</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes">Let&apos;s have a great day tomorrow!</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where"> - </p>
            </td>
          </tr>

          <tr className="table-row table-color--green">
            <td className="table-column table-column--2b">
              <h2 className="row-label">Saturday</h2>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--time">6:30AM-8:00AM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">Fellowship Coffee and Muffins</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes"> - </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Dining Hall</p>
            </td>
          </tr>

          <tr className="table-row table-color--green">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">7:30AM-8:00AM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">[Optional] Calisthenics</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes">Need Volunteers</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Outdoors</p>
            </td>
          </tr>

          <tr className="table-row table-color--green">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">8:00AM-9:00AM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">Breakfast</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes"> - </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Dining Hall</p>
            </td>
          </tr>

          <tr className="table-row table-color--green">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">9:30AM-10:45AM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">[Messages] &quot;Timothy Sessions&quot;</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes"> Speakers: Young Men </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Chapel</p>
            </td>
          </tr>

          <tr className="table-row table-color--green">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">10:45AM-11:00AM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">Break</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes">Stretch Legs</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Chapel</p>
            </td>
          </tr>

          <tr className="table-row table-color--green">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">11:00AM-12:00AM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">[Message] </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes">Speaker:</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Chapel</p>
            </td>
          </tr>

          <tr className="table-row table-color--green">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">12:00PM-1:30PM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">Lunch</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes"> - </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Dining Hall</p>
            </td>
          </tr>

          <tr className="table-row table-color--green">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">1:30PM-3:30PM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">Free Time</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes"> - </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where"> - </p>
            </td>
          </tr>

          <tr className="table-row table-color--green">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">3:30PM-4:30PM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">[Message]</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes">Speaker: </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Chapel</p>
            </td>
          </tr>

          <tr className="table-row table-color--green">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">4:30PM-5:30PM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">Free Time</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes"> - </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where"> - </p>
            </td>
          </tr>

          <tr className="table-row table-color--green">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">5:30PM-7:00PM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">Supper</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes"> - </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Dining Hall</p>
            </td>
          </tr>

          <tr className="table-row table-color--green">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">7:00PM-9:00PM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">[Message] &quot;Speaker Q&amp;A&quot;</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes"> - </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Chapel</p>
            </td>
          </tr>

          <tr className="table-row table-color--green">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">9:00PM-10:30PM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">Music and Fellowship</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes">Bring instruments and music!</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Chapel</p>
            </td>
          </tr>

          <tr id="Sunday" />
          <tr className="table-row table-color--green">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">11:00PM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">Bedtime</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes">Get some rest!</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Cabins</p>
            </td>
          </tr>

          <tr className="table-row table-color--red">
            <td className="table-column table-column--2b">
              <h2 className="row-label">Sunday</h2>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--time">6:30AM-8:00AM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">Fellowship Coffee and Muffins</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes"> - </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Dining Hall</p>
            </td>
          </tr>

          <tr className="table-row table-color--red">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">8:00AM-9:30AM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">Breakfast</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes"> - </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Dining Hall</p>
            </td>
          </tr>

          <tr className="table-row table-color--red">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">9:30AM-10:45AM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">[Message] </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes">Speaker: </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Chapel</p>
            </td>
          </tr>

          <tr className="table-row table-color--red">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">10:45AM-11:00AM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">Break</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes">Stretch Legs</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Chapel</p>
            </td>
          </tr>

          <tr className="table-row table-color--red">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">11:00AM-12:00PM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">[Message] </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes">Speaker: </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Chapel</p>
            </td>
          </tr>

          <tr className="table-row table-color--red">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">12:00PM-1:30PM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">Lunch</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes"> - </p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">Dining Hall</p>
            </td>
          </tr>

          <tr className="table-row table-color--red">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">1:30PM-2:30PM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">Clean Up</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes">Clean cabins, chapel, and dining hall.</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where">All Buildings</p>
            </td>
          </tr>

          <tr className="table-row table-color--red">
            <td className="table-column table-column--2b" />
            <td className="table-column table-column--2b">
              <p className="column-label--time">3:00PM</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--event">Checkout and Departure</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--notes">See you next year!</p>
            </td>
            <td className="table-column table-column--2b">
              <p className="column-label--where"> - </p>
            </td>
          </tr>

        </tbody>
      </table>
    );
  }

}

export default RegisterForm;
